import './A.css';
import {forwardRef} from 'react';
import {classNames} from '../../utilities/classNames';
import {Link} from '@remix-run/react';

export const A = forwardRef<HTMLAnchorElement, UI.Link.Props>(function A(
  {children, className, tone, href, ...attributes},
  ref,
) {
  const classes = classNames('A', className, {
    'tone-default': tone == null || tone === 'default',
    'tone-danger': tone === 'danger',
    'tone-info': tone === 'info',
    'tone-neutral': tone === 'neutral',
    'tone-success': tone === 'success',
    'tone-warning': tone === 'warning',
  });

  return (
    <Link {...attributes} className={classes} ref={ref} to={href ?? '#'}>
      {children}
    </Link>
  );
});
