import {forwardRef} from 'react';
import {classNames} from '../../utilities/classNames';

export const Icon = forwardRef<SVGSVGElement, UI.Icon.Props>(function Icon(
  {name, size = 'font', className, ...props},
  ref,
) {
  const classes = classNames('Icon', className, {
    'w-[1em] h-[1em]': size === 'font',
    'w-3 h-3': size === 'xs',
    'w-4 h-4': size === 'sm',
    'w-5 h-5': size === 'md',
    'w-6 h-6': size === 'lg',
    'w-7 h-7': size === 'xl',
  });

  return (
    <svg {...props} ref={ref} className={classes}>
      <use href={`/icons/sprite.svg#${name}`} />
    </svg>
  );
});
